import { format } from "date-fns"; // For date formatting
import React, { useState, useEffect, useContext } from "react";
import { CgSpinner } from "react-icons/cg";

import ReceptionistDashboard from "../CashierProfile/ReceptionistDashboard";
import ReceptionHeader from "../CashierHeader/ReceptionHeader";

import toast from "react-hot-toast";

import CashierExpenseList from "../CashierExpenseList/CashierExpenseList";
import "./CashierAllExpenses.css";

import { useNavigate } from "react-router-dom";

import { Store } from "../../../../Store";




const CashierAllExpenses = () => {
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  
  
  const [categoryFilter, setCategoryFilter] = useState(""); // State for category filter
  const [selectedDate, setSelectedDate] = useState(""); // State for specific date filter

 
  const [expenses, setExpenses] = useState([]);
  
  
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { dispatch } = useContext(Store);
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const receivedby = retrievedInfo.fullName;
  
  console.log(receivedby);
  
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/CashierAllExpenses", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/CashierAllExpenses");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  useEffect(() => {

    
    const fetchExpenses = async () => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/all-expenses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setExpenses(data.data);

        // Show success toast
        // toast.success("Payments fetched successfully!");
      } catch (error) {
        setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
   

  fetchExpenses();
    
}, [apiUrl]);




  
  
  const filteredItems = expenses?.filter((item) => {
    const matchesCategory = categoryFilter
      ? item.category.toLowerCase().includes(categoryFilter.toLowerCase())
      : true;

    const itemDate = format(new Date(item.createdAt), "yyyy-MM-dd"); // Format item date
    const matchesDate = selectedDate ? itemDate === selectedDate : true;
    
    const isInactive = item.isActive === false; // Check if isActive is false
    
    return matchesCategory && matchesDate && isInactive;
  });
  
  

  const totalAmount =
    filteredItems?.reduce((sum, item) => sum + item.amount, 0) || 0;
    
    
  const handleResetFilters = () => {
    setCategoryFilter("");
    setSelectedDate("");
  };
  
  
  
  return (
    <div className="AdminAccounts">
    
      <div className="AdminAccounts-profile">
        <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      <div className="AdminAccounts-space">
        <div className="AdminAccounts-mobile-header">
          <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
        </div>
        
        
        
        
        <div className="AdminAccounts-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="AdminAccounts-space-body">
          <div className="AdminAccounts-title-board">
          
          
            <h2>Expenses:</h2>
            
            {isLoading || isLoading ? (
              <CgSpinner
                className="animate-spin"
                style={{ color: "red" }}
                size={40}
              />
            ) : null}
            
            <div className="filter-controls">
              {/* Date Picker */}
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                style={{ color: "black", padding: 7 }}
              />

              {/* Category Filter */}
              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>

              <button onClick={handleResetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
          <section className="AdminAccounts-board">
            <div className="AdminAccounts-center-board">
              <div className="AdminAccounts-table-head">
                <p>Staff</p>
                <p>Date</p>
                <p>Category</p>
                <p>Description</p>
                <p>Amount</p>
              </div>
              
              <div className="AdminAccounts-table-body">
                {isLoading || isLoading ? (
                  <span style={{ color: "black" }}>loading...</span>
                ) : error ? (
                  <span style={{ color: "red" }}>{error}</span> // Display error message
                ) : filteredItems?.length > 0 ? (
                  filteredItems.map((item, index) => {
               

                    const formattedDate = format(
                      new Date(item.createdAt),
                      "yyyy-MM-dd"
                    );

                    return (
                      <CashierExpenseList
                        key={index}
                        name={item?.madeBy || "Unknown"}
                        date={formattedDate}
                        category={item.category}
                        description={item.description}
                        amount={item.amount}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <span style={{ color: "black" }}>No records found</span>
                )}
              </div>
              <div className="ExpenseAccounts-totals">
                <h5>TOTAL</h5>
                <h5>{parseFloat(totalAmount)}</h5>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CashierAllExpenses;
