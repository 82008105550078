/** @format */

import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { Store } from "../../../../Store";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import "./AssignRoom.css";
import { CgSpinner } from "react-icons/cg";




let uniqueNo = ""; // Declare uniqueNo as a global variable


const AssignRoom = () => {
    
  const { id } = useParams();
  
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const { dispatch } = useContext(Store);
  const navigate = useNavigate();
  
  const [idx, setIdx] = useState("");
  const [names, setNames] = useState("");
  
  
  const [identifier, setIdentifier] = useState("");
  const [type, setType] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [room, setRoom] = useState("");
  const [collectedBy, setCollectedBy] = useState("");
  const [assignDate, setAssignDate] = useState(""); // New state for date
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [guestIdInput, setGuestIdInput] = useState(""); // Editable guest ID state
  
  
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const collectedFrom = retrievedInfo.staffUniqueId;
  const staffName = retrievedInfo.fullName;
  
  const [selectedGuest, setSelectedGuest] = useState({ id: '', fullName: '' });
  const [guestList, setGuestList] = useState([]);
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  useEffect(() => {
    if (id) {
      const savedProps = JSON.parse(localStorage.getItem("availableRoomsProps")) || {};
      if (savedProps.amount && savedProps.room) {
        setIdentifier(savedProps.id || "N/A");
        setTotalAmount(savedProps.amount || "N/A");
        setRoom(savedProps.room || "N/A");
      } else {
        navigate("/ManageGuest");
        // toast.error("Invalid guest dashboard data!");
      }
    } else {
      navigate("/ManageGuest");
      toast.error("Please select a customer!");
      return
    }
  }, [id, navigate]);
  
  
  
  





  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          
          
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/AssignRoom/:id", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/AssignRoom/:id");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  useEffect(() => {
    const fetchGuests = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/all-guest`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch guests.");
        }

        const data = await response.json();
        setGuestList(data.data);
        // toast.success("Guests fetched successfully!");
      } catch (err) {
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGuests();
  }, [apiUrl]);
  
  
  
  
  const handleGuestIdChange = (e) => {
    const inputValue = e.target.value;
    setGuestIdInput(inputValue);

    // Check if the input has 9 characters
    if (inputValue.length === 6) {
      // Perform the guest ID check
      const matchingGuest = guestList.find((guest) => guest.userUniqueId === inputValue);

      if (matchingGuest) {
        // If a matching guest is found, set the selected guest
        setCollectedBy(matchingGuest.userUniqueId);
        setSelectedGuest(matchingGuest);
      } else {
        // If no match is found, show an error toast
        toast.error("No matching Guest ID found.");
        setSelectedGuest({ userUniqueId: "", fullName: "" });
        setCollectedBy(""); // Reset the collectedBy field if no match
      }
    }
  };
  
  
  
  
  const handleSelectChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedUniqueId = e.target.value;
    const selectedGuestData = guestList.find((guest) => guest.userUniqueId === selectedUniqueId);
    const id = selectedOption.getAttribute('data-id'); // _id
    const name = selectedOption.getAttribute('data-name'); // fullName
    const uniqueNoFromOption = selectedOption.getAttribute('data-unique'); // fullName
    setIdx(id);
    setNames(name);
    
    // Update the global uniqueNo variable
    uniqueNo = uniqueNoFromOption;
  
  
    if (selectedGuestData) {
      setSelectedGuest(selectedGuestData);
      setGuestIdInput(selectedGuestData.userUniqueId); // Set the uniqueId to collectedBy
    } else {
      setSelectedGuest({ userUniqueId: '', fullName: '' });
      setGuestIdInput(''); // Reset collectedBy if no guest is selected
    }
  };
  
  
  
  const handleCheckinChange = () => {
    setType("Checkin");
  };

  const handleReservationChange = () => {
    setType("Reservation");
  };
 
 
 
  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      console.error("Invalid JSON data:", e.message);
      return false;
    }
  }
 
 
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
   
    
    
    if (!id) {
      toast.error("Room ID is missing");
      return;
    }
    
    if (!collectedFrom) {
      toast.error("Staff ID is missing");
      return;
    }
    
    
    if (!collectedBy) {
      toast.error("Guest ID is required");
      return;
    }
    

    if (!type) {
      toast.error("Please select either Checkin or Reservation.");
      return;
    }
  
    
    if (type === "Reservation" && !assignDate) {
      toast.error("Please select an assign date");
      return;
    }
  
   
    const numericPrice = parseFloat(totalAmount.replace(/[^0-9.]/g, "")); 
    const requestData = {
     isAvailable: false,
     assignedDate: new Date(assignDate),
     collectedFrom,
     collectedBy,
     type,
     price: numericPrice,
     staffName,
    };
    
    
    
    setIsLoading(true); // Set loading state

    try {
     const jsonString = JSON.stringify(requestData);
     if (isValidJSON(jsonString)) {
      const response = await fetch(`${apiUrl}/api/rooms/update-room/${identifier}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: jsonString,
      });

      if (response.ok) {
        const id = idx;
        
        toast.success("Room assigned successfully");
        console.log("Navigating to GuestDashBoardRoom");
        localStorage.setItem( "guestDashboardProps", JSON.stringify({ uniqueNo, id, names})
)
        navigate(`/GuestDashBoardRoom/${uniqueNo}`);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred");
      }
     } else {
       // Log the exact data causing the JSON parse error in a toast
       toast.error(`Invalid JSON data: ${jsonString}`);
       throw new Error("Invalid requestData format");
     }
      
    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Room Assignment Failed: ${error.response.data.message}`);
      } else {
        toast.error(`Room Assignment Failed: ${error.message}`);
    }
    } finally {
    setIsLoading(false); // Reset loading state
    }
    };
  
  
  
  
  
  
  
  
 
 
  
  return (
    <div className="assignRoom">
    
      {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
    
    
      <div className="assignRoom-hue">
      
      
        <div className="assignRoom-head">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>
      
      

        
        <div className="assignRoom-content-section">
        <div className="assignRoom-mobile-head">
         <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
        />
        <ReceptionHeader
            setReceptionProfileShow={setReceptionProfileShow}
        />
        </div>
        
        
        
        
        
        
        <div className="assignRoom-main-body">
        
        

        
        
        <form onSubmit={handleSubmit} className="assignRoom-body">
          <div className="assignRoom-body-top">
            <h3>Assign Room</h3>
            
            <div className="assignRoom-input-id">
            <label htmlFor="guest_id">Assign Room:</label>
            
            <select
              value={selectedGuest?.userUniqueId || ''}
              onChange={handleSelectChange}
              name="guest_id"
              style={{ color: 'black', padding: '5px' }}
            >
            <option value="" disabled>
              Select Guest
            </option>
            {guestList.map((guest) => (
              <option key={guest.userUniqueId} 
                      value={guest.userUniqueId}
                      data-id={guest._id} 
                      data-name={guest.fullName}
                      data-unique={guest.userUniqueId}
              >
                {guest.fullName}
              </option>
            ))}
            </select>
            
           </div>
         
          
              {/* Uneditable Input Field */}
          <div className="assignRoom-guest-id">
            <label htmlFor="guestId">Guest ID:</label>
            <input
              type="text"
              value={guestIdInput}
              onChange={handleGuestIdChange}
              name="guestId"
              placeholder="Guest ID"
          />
          </div>
        
       </div>
          
          
          <div style={{ height: '90px' }}></div> {/* Spacer to create vertical space */}

    
          
          
    
          
          <div className="assignRoom-body-middle">
          
            <p>Room: {room} </p> <div></div> <p> Price: {totalAmount} </p>
            
            <div className="assignRoom-middle-checkbox">
            
            
              <label htmlFor="assign_room">
                <input
                  type="checkbox"
                  value="Checkin"
                  checked={type === "Checkin"}
                  onChange={handleCheckinChange}
                  name="Checkin"
                />
                Check in
              </label>
              
              
              <label htmlFor="assign_room">
                <input
                  type="checkbox"
                  value="Reservation"
                  checked={type === "Reservation"}
                  onChange={handleReservationChange}
                  name="Reservation"
                />
                Reservation
              </label>
              
              
            </div>
            
            
            {type === "Reservation" && (
               <div className="assign-date-container">
                 <input
                  type="date"
                  name="assign_date"
                  id="assign_date"
                  className="assign_date"
                  value={assignDate} // Bind the value to state
                  onChange={(e) => setAssignDate(e.target.value)} // Update state on change
                 />
               </div>
            )}
            
            
         
          
          
          
          <div className="assignRoom-body-bottom">
            
            {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
            <button type="submit">Assign</button>
            )}
            
          </div>
          </div>
          
          
        </form>
        
       </div>
       </div>
      </div>
    </div>
  );
};

export default AssignRoom;
