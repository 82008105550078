import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";  // For the spinner



import { Store } from "../../../../Store";
import ReceptionHeader from "../BartenderHeader/ReceptionHeader";
import ReceptionistDashboard from "../BartenderProfile/ReceptionistDashboard";


import "./UpdateGuestPayment.css";

const BarUpdateGuestPayment = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  
    
  const { identity } = useParams();
  
  
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [collectedBy, setCollectedBy] = useState("");
  const [collectedFrom, setCollectedFrom] = useState("");
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [payment, setPayment] = useState([]);
  const [rooms, setRooms] = useState(null);
  const [guest, setGuest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null); // To handle and display error messages
  const [staffInfoo, setStaffInfoo] = useState({});
  
  const [totalCost, setTotalCost] = useState("");
  const [discount, setDiscount] = useState("");
  
  const [category, setCategory] = useState("Room");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [comment, setComment] = useState("");
  const [bill, setBill] = useState(""); // Total bill amount
  const [paid, setPaid] = useState(""); // Amount paid
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showCommentBox, setShowCommentBox] = useState(false);

 
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }

  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/BarUpdateGuestPayment/:identity", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/BarUpdateGuestPayment/:identity");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  useEffect(() => {
  
    const fetchGuest = async (identifier) => {
      // if (!uniqueNo) return;
      
      setIsLoading(true);
      // setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/single-guest/${identifier}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setGuest(data.data);
        // toast.success("Guest data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        // setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    
    

    const fetchRoomDetails = async (identifier) => {
      // if (!identifier) return;

      setIsLoading(true);
      // setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/rooms/view-room/${identifier}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch guest details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setRooms(data.data);

        // Show success toast
        toast.success("Room details fetched successfully!");
      } catch (error) {
        // setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
  
  
    const fetchPayments = async (index) => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      // setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/user-payments/${index}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setPayment(data.data);

        // Show success toast
        toast.success("Payments fetched successfully!");
      } catch (error) {
        // setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
    const handleFetch = async () => {
    if (identity) {
      const staffProps = JSON.parse(localStorage.getItem("staffInfo")) || {};
      const staffId = staffProps.fullName || "N/A";
      setStaffInfoo(staffProps);
      setCollectedBy(staffId);
      const savedProps = JSON.parse(localStorage.getItem("guestDashboardProps")) || {};
      if (savedProps.indexx && savedProps.identity) {
        // Use savedProps directly for immediate availability
        const identifier = savedProps.identity || "N/A";
        const index = savedProps.indexx || "N/A";
        const name = savedProps.name || "N/A";

        // Update state after ensuring the values are ready
        setCollectedFrom(identifier);
        setId(index);
        setName(name);

        // Pass the values directly to fetchGuest if needed
        await fetchGuest(identifier);
        await fetchRoomDetails(identifier);
        await fetchPayments(index)
      } else {
        toast.error("Invalid guest dashboard data!");
      }
    } else {
      navigate("/BartenderManageGuest");
      toast.error("Please select a customer!");
    }
  };

  handleFetch();
    
}, [apiUrl, identity, navigate]);
  
  
  
  
  
  
  
  

  
  useEffect(() => {
  try {
    if (category === "Room" && selectedRoom) {
      const selectedRoomData = rooms?.find(
        (room) => room._id === selectedRoom
      );
      
      if (!selectedRoomData) {
        toast.error("Selected room not found.");
        setBill("");
        setSelectedItemId(null);
        return;
      }

      // Check if the selected room already has a payment
      const existingPayment = payment?.find((pay) =>
        pay.comment.includes(`id:${selectedRoom}`)
      );

      if (existingPayment) {
        setBill(existingPayment.bill - existingPayment.paid);
        setSelectedItemId(existingPayment._id); // Save the payment ID for updates
        // toast.success("Existing payment details loaded.");
      } else {
        setBill(selectedRoomData.price);
        setSelectedItemId(null);
        // toast.info("Room selected. Ready for new payment.");
      }
    } else if (selectedItemId) {
      const existingPayment = payment?.find(
        (item) => item._id === selectedItemId
      );

      if (existingPayment) {
        setBill(existingPayment.bill - existingPayment.paid);
        // toast.success("Existing item payment details loaded.");
      } else {
        toast.error("Payment details for the selected item not found.");
        setBill("");
      }
    } else {
      setBill("");
    }
  } catch (error) {
    console.error("Error loading payment details:", error);
    toast.error("An error occurred while loading payment details.");
  }
}, [category, selectedRoom, selectedItemId, rooms, payment]);

  
  
  
  
  const handleRoomSelection = (e) => {
  try {
    const roomId = e.target.value;
    setSelectedRoom(roomId);

    const selectedRoomData = rooms?.find((room) => room._id === roomId);

    if (!selectedRoomData) {
      toast.error("Room not found. Please select a valid room.");
      setBill("");
      return;
    }

    setBill(selectedRoomData.price);
    toast.success(`Room ${selectedRoomData.name} selected. Bill set to N ${selectedRoomData.price}`);
  } catch (error) {
    console.error("Error selecting room:", error);
    toast.error("An error occurred while selecting the room.");
  }
};




  const savePayment = async (paymentData) => {
  try {
    const response = await fetch(`${apiUrl}/api/payment/make-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers here, such as authentication tokens
      },
      body: JSON.stringify(paymentData),
    });

    // Check if the response status is OK
    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(`Error: ${response.status} - ${errorDetails.message || 'Payment failed'}`);
    }

    const responseData = await response.json();
    
    // Display success toast
    toast.success('Payment successful!');

    // Return success response data
    return responseData;
  } catch (error) {
    // Handle and log detailed error
    console.error('Error making payment:', error.message || error);

    // Display error toast
    toast.error(`Payment failed: ${error.message || 'An error occurred'}`);

    throw error; // Re-throw error after logging
  }
};






  
  
  const handleDiscountChange = (e) => {
    const input = e.target.value;

    // Allow empty input to reset discount
    if (input === '') {
      setDiscount('');
      return;
    }

    // Only allow numbers and optional decimal points during typing
    if (/^\d*\.?\d*$/.test(input)) {
      setDiscount(input); // Store the input as string temporarily
      const value = parseFloat(input);
      const maxDiscount = totalCost;

      if (!isNaN(value) && value >= 0 && value <= maxDiscount) {
      calculateBill(value);
      } else if (value > maxDiscount) {
        calculateBill(maxDiscount);
      }
    }
  };






  


  

  const calculateBill = (discount = 0) => {
    
    const discountedCost = totalCost - discount; // Subtract exact discount amount
    setBill(discountedCost > 0 ? discountedCost.toFixed(2) : "0.00"); // Ensure no negative bill
    
  };







  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    
    
    if (!category) {
      toast.error("Please select a payment category.");
      return;
    }

    if (category === "Room" && !selectedRoom) {
      toast.error("Please select a room.");
      return;
    }
    
    

    if (!bill) {
      toast.error("Please enter the bill amount.");
      return;
    }

    if (!paid) {
      toast.error("Please enter the paid amount.");
      return;
    }
  
    
    if (category !== "Room" && !comment) {
      alert("Please enter a comment for the selected category.");
      return;
    }
    
    if (category !== "Room" && !totalCost) {
      alert("Please enter the total amount!");
      return;
    }
    
    
    
    // Set loading state before making the request
    setIsLoading(true);
    
    
    
    const paidNumber = Number(paid);
    const billNumber = Number(bill);

    // Validate if paid amount is greater than the bill
    if (paidNumber > billNumber) {
      toast.error("Amount paid cannot be greater than the bill.");
      setIsLoading(false);
      return;
    }

   let finalComment = comment;
  
   // Handle category-specific logic for comments
   if (category === "Room") {
     const selectedRoomData = rooms?.find((room) => room._id === selectedRoom);
      finalComment = selectedRoomData
      ? `[name:${selectedRoomData.name},id:${selectedRoomData._id},createdAt:${selectedRoomData.createdAt}]`
      : "";
  }

    // Check if a payment already exists for the selected category and room
    const existingPayment = payment?.find(
      (item) => item.category === category && item.selectedRoom === selectedRoom
    );

    const totalPaid = existingPayment
      ? Number(existingPayment.paid) + paidNumber
      : paidNumber;

    try {
       const paymentData = {
        collectedFrom,
        collectedBy,
        userid: id,
        staffid: staffInfoo._id,
        category,
        bill: billNumber,
        paid: totalPaid,
        
        comment: finalComment,
        selectedRoom,
        catid: selectedItemId, // Send the category ID
       };

      // Call savePayment function
      await savePayment(paymentData);
      
      
      const uniqueNo = collectedFrom;
      const names = name;
      
      
      // Display success message and navigate to dashboard
      toast.success("Payment updated successfully");
      
      localStorage.setItem( "guestDashboardProps", JSON.stringify({ uniqueNo, id, names}));

      navigate(`/BarGuestDashBoardRoom/${uniqueNo}`);
      
    } catch (error) {
      // Log error and show error toast
      console.error("Error updating payment:", error);
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      // Stop loading after the operation is complete
      setIsLoading(false);
    }
 };
 
 
 
 
 
  const toggleCommentBox = () => {
    setShowCommentBox((prevState) => !prevState);
  };
  
  
  
  
 
  return (
  <div className="UpdateGuestPayment">
  
  
  
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
  
  
    <div className="UpdateGuestPayment-hue">
    
    
    
    
    
      <div className="UpdateGuestPayment-head">
       <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      
      
      
      
      
      <div className="updateGuestPayment-content-section">
        
        <div className="updateGuestPayment-mobile-head">
          <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader
             setReceptionProfileShow={setReceptionProfileShow}
          />
        </div>
      
      
      
      
      
      <div className="updateGuestPayments-body">
      
      
      
      
      
      
      <div className="UpdateGuestPayment-body">
        <form
          onSubmit={handleSubmit}
          className="UpdateGuestPayment-form"
          style={{ marginTop: -50 }}
        >
          <div className="UpdateGuestPayment-header-details">
            <p className="UpdateGuestPayment-title">Update Payments</p>
            <h1>{guest?.fullName}</h1>
            <p className="UpdateGuestPayment-staff">
              Collected by: {staffInfoo ? staffInfoo.fullName : ""}
            </p>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              id="paymentCategory"
            >
              <option value="">Select Category to update</option>
              <option value="Laundry">Laundry</option>
              {staffInfoo &&
                (staffInfoo.position === "Admin" ||
                  staffInfoo.position === "Receptionist") && (
                  <option value="Room">Room</option>
                )}
              <option value="Pool">Pool</option>
              <option value="Restaurant">Restaurant</option>
              <option value="Bar">Bar</option>
              <option value="Others">Others</option>
            </select>
          </div>






          {/* Room Payment Section */}
{category === "Room" ? (
  <>
    {staffInfoo &&
      (staffInfoo.position === "Admin" || staffInfoo.position === "Receptionist") && (
        <select
          value={selectedRoom}
          onChange={handleRoomSelection}
          className="room-dropdown"
          required
        >
          <option className="room-option">Select Room</option>
          {rooms?.map((room) => {
            const matchingPayment = payment?.find(
              (pay) =>
                pay.category === "Room" &&
                pay.comment.includes(`id:${room._id}`)
            );

            return (
              <option
                className="room-option"
                key={room._id}
                value={room._id}
              >
                {room.name} {"  "}
                {matchingPayment
                  ? `( Bill: N ${matchingPayment.bill}, Paid: N ${matchingPayment.paid} )`
                  : `( N ${room.price} )`}
              </option>
            );
          })}
        </select>
      )}


    



    <div className="existing-payments">
      {payment
        ?.filter(
          (pay) =>
            pay.category === "Room" &&
            pay.selectedRoom === selectedRoom
        )
        .map((pay, index) => (
          <div key={index} className="payment-detail">
            <p>Customers Name: {name}</p>
            <p>Bill: N {pay.bill}</p>
            <p>Paid: N {pay.paid}</p>
            <p>Comment: {pay.comment}</p>
          </div>
        ))}
    </div>
  </>
) : (


  <>
  {/* Other Payment Categories */}
  {payment?.some((item) => item.category === category) ? (
    <>
      <button type="button" onClick={toggleCommentBox}>
        {showCommentBox
          ? `Update existing ${category} Items`
          : `Add new ${category} Items`}
      </button>

      {!showCommentBox && (
        <select
          value={selectedItemId}
          onChange={(e) => setSelectedItemId(e.target.value)}
          className="room-dropdown"
        >
          <option value="">{`Select ${category} item to update`}</option>
          {payment
            .filter((item) => item.category === category)
            .map((item) => (
              <option key={item._id} value={item._id}>
                {item.comment} (Bill: {item.bill}) (Paid: {item.paid})
              </option>
            ))}
        </select>
      )}

      {showCommentBox && (
        <>
          <textarea
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
            cols={50}
            placeholder={`Add additional ${category} items`}
          />

          <label>
            Total:
            <input
              type="text"
              value={totalCost}
              onChange={(e) => setTotalCost(e.target.value)}
            />
          </label>
        </>
      )}
    </>
  ) : (
    <>
      <textarea
        name="comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        rows={4}
        cols={50}
        placeholder="Comments"
      />

      <label>
        Total:
        <input
          type="text"
          value={totalCost}
          onChange={(e) => setTotalCost(e.target.value)}
        />
      </label>
    </>
  )}
</>

)}
    
    
    
    
    
    {/* Discount Section */}
    
    
   {category === "Room"
  ? rooms?.map((room) => {
      const matchingPayment = payment?.find(
        (pay) =>
          pay.category === "Room" && pay.comment.includes(`id:${room._id}`)
      );

      return selectedRoom === room._id && !matchingPayment ? (
        <label key={room._id}>
          Discount:
          <input
            type="number"
            value={discount}
            onChange={(e) => handleDiscountChange(e)}
            className="room-option"
            placeholder="Enter Discount Amount"
          />
        </label>
      ) : null;
    })
  :  (
      <label>
        Discount:
        <input
          type="number"
          value={discount}
          onChange={(e) => handleDiscountChange(e)}
          className="room-option"
          placeholder={`Enter Discount Amount for ${category}`}
        />
      </label>
    )}





  
    







    
    
       
       {/* Bill Display */}
    <label>
      Bill:
      <input
        value={bill}
        onChange={(e) => setBill(e.target.value)}
        type="text"
        readOnly={category === "Room"}
      />
    </label>
    
    
    <label>
     Paid:
     <input
       type="text"
       value={paid}
       onChange={(e) => setPaid(e.target.value)}
     />
     </label>
     
     
     
     {/* Loading Spinner or Error */}
          {isLoading ? (
            <div className="spinner-container">
              <CgSpinner size={30} className="spinner" />
            </div>
          ) : (
            <button type="submit" disabled={isLoading}>
            Update
          </button>
          )}
          
          
          
     


          
        </form>
        
        
        
        
      </div>
      
    
    
       </div>
    </div>
    </div>
  </div>
);
  
};

export default BarUpdateGuestPayment;
