import React, { useContext, useState, useEffect } from "react";
import "./ReservationInfo.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Store } from "../../../../Store";
import toast from "react-hot-toast";
import { CgSpinner } from 'react-icons/cg';

import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";




const ReservationInfo = () => {
  const { guest } = useParams();
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [isLoading, setIsLoading] = useState(false);
  
  const [checkinLoading, setCheckinLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  // const [error, setError] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [guests, setGuests] = useState("");
  const [roomId, setRoomId] = useState("");
  const [date, setDate] = useState("");
 
 
 
  // DateFormatter Component
  const DateFormatter = ({ isoDate }) => {
    const formatDate = (isoDateString) => {
      const date = new Date(isoDateString);
      if (isNaN(date)) return "Invalid Date"; // Handle invalid dates gracefully

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}.${month}.${year}`;
    };

    return <>{formatDate(isoDate)}</>;
  };


  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      console.error("Invalid JSON data:", e.message);
      return false;
    }
  }
 
  
  
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  

  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/ReservationInfo/:guest", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/ReservationInfo/:guest");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  useEffect(() => {
  
    const fetchGuest = async (identifier) => {
      // if (!uniqueNo) return;
      
      setIsLoading(true);
      // setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/single-guest/${identifier}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setGuests(data.data);
        // toast.success("Guest data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        // setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    const handleFetch = async () => {
    if (guest) {
      const savedProps = JSON.parse(localStorage.getItem("guestDashboardProps")) || {};
      if (savedProps.id && savedProps.guest) {
        // Use savedProps directly for immediate availability
        const identifier = savedProps.guest || "N/A";
        const index = savedProps.roomId || "N/A";
        const name = savedProps.room || "N/A";
        const datee = savedProps.date
        // Update state after ensuring the values are ready
        setRoomId(index);
        setRoomName(name);
        setDate(datee);

        // Pass the values directly to fetchGuest if needed
        await fetchGuest(identifier);
        
      } else {
        toast.error("Invalid guest dashboard data!");
      }
    } else {
      navigate("/ManageGuest");
      toast.error("Please select a reserved room!");
    }
  };

  handleFetch();
    
}, [apiUrl, guest, navigate]);



  
  
  const handleCheckIn = async (e) => {
    e.preventDefault();
    
    setCheckinLoading(true); // Set loading state
    
    try {
     const assignmentData = {
       type: "Checkin",
     };

     const jsonString = JSON.stringify(assignmentData);
     if (isValidJSON(jsonString)) {
      const response = await fetch(`${apiUrl}/api/rooms/update-room/${roomId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: jsonString,
      });

      if (response.ok) {
        const id = roomId;
        const uniqueNo = guests.userUniqueId;
        const names = guest.fullName;
        
        
        toast.success("Room assigned successfully");
        console.log("Navigating to GuestDashBoardRoom");
        localStorage.setItem( "guestDashboardProps", JSON.stringify({ uniqueNo, id, names})
)
        navigate(`/GuestDashBoardRoom/${uniqueNo}`);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred");
      }
     } else {
       // Log the exact data causing the JSON parse error in a toast
       toast.error(`Invalid JSON data: ${jsonString}`);
       throw new Error("Invalid requestData format");
     }
      
    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Room Assignment Failed: ${error.response.data.message}`);
      } else {
        toast.error(`Room Assignment Failed: ${error.message}`);
    }
    } finally {
    setCheckinLoading(false); // Reset loading state
    }
    };




  // Handle Delete button (update room to be available and remove type)
  const handleDelete = async () => {
    
    setCheckoutLoading(true); // Set loading state
    
    try {
      const assignmentData = {
        type: "",
        isAvailable: true,
      };

      const jsonString = JSON.stringify(assignmentData);
     if (isValidJSON(jsonString)) {
      const response = await fetch(`${apiUrl}/api/rooms/update-room/${roomId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: jsonString,
      });

      if (response.ok) {
        
        toast.success("Room Removed from Reservation successfully");
        
        navigate(`/StaffDashboard`);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred");
      }
     } else {
       // Log the exact data causing the JSON parse error in a toast
       toast.error(`Invalid JSON data: ${jsonString}`);
       throw new Error("Invalid requestData format");
     }
      
    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Room Unreservation Failed: ${error.response.data.message}`);
      } else {
        toast.error(`Room Unreservation Failed: ${error.message}`);
    }
    } finally {
    setCheckoutLoading(false); // Reset loading state
    }
    };
    
    
    
  
  
  
  
  
  
  
  

  return (
    <div className="ReservationInfo">
      <div className="ReservationInfo-web">
      
      
      
        <div className="ReservationInfo-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>
        
        

        <div className="ReservationInfo-content-section">
          <div className="ReservationInfo-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>
          
          
          
          <div className="ReservationInfo-content-head">
            <Link to="/StaffDashboard">Back to Homepage</Link>
          </div>
          <div className="ReservationInfo-content-body">
            <div className="ReservationInfo-content-table-section">
              <div className="ReservationInfo-web-table-holder">
                <section className="ReservationInfo-section">
                
                
                  <form onSubmit={handleCheckIn}>
                    <div>
                      <h2>{guests?.fullName || "Guest Name"}</h2>
                      <p>+{guests?.phoneNumber || "Guest Phone"}</p>
                      <p>
                        <DateFormatter isoDate={isNaN(new Date(date)) ? "" : new Date(date)} />;
                      </p>
                    </div>
                    <div>
                      <p>{roomName}</p>
                    </div>
                    <div className="ReservationInfo-buttons">
                    
                      <button
                         type="submit"
                         style={{ width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}
                         disabled={isLoading} // Disable button while loading
                      >
                         {isLoading || checkinLoading ? <CgSpinner className="spinner" style={{ animation: "spin 1s linear infinite" }} /> : "CHECK IN"}
                      </button>
                      
                      
                      <button
                         type="button"
                         style={{ width: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}
                         onClick={handleDelete}
                         disabled={isLoading} // Disable button while loading
                      >
                         {isLoading || checkoutLoading? <CgSpinner className="spinner" style={{ animation: "spin 1s linear infinite" }} /> : "DELETE"}
                     </button>
                      
                      
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationInfo;
