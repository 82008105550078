/** @format */

import React, { useState, useContext, useEffect } from "react";
import "./OnlineBookingList.css";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import { Link } from "react-router-dom";
import OnlineBookingListItem from "../OnlineBookingListItem/OnlineBookingListItem";
import { CgSpinner } from "react-icons/cg";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import { Store } from "../../../../Store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";




const GuestRoomDetails = [
  {
    name: "Peter Patrick F.",
    address: "Abuja",
    phoneNo: "+2345667940",
  },
  {
    name: "Agbo James",
    address: "14 Hauston Texas",
    phoneNo: "+2345667940",
  },
  {
    name: "Fanen Torkura JP",
    address: "S.A",
    phoneNo: "+2345667940",
  },
];





const OnlineBookingList = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

 
  
  const navigate = useNavigate();
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  const { state: { staffInfo }, dispatch } = useContext(Store);
  
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/OnlineBookingList", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/OnlineBookingList");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  
 

  React.useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/bookings/view-booking`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setBookings(data.data);
        // toast.success("Staff data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    if(staffInfo){
      fetchBookings();
    }
  }, [apiUrl, staffInfo]);
  
  
  
  

  return (
    <>
      <div className="OnlineBookingList">
        <div className="OnlineBookingList-web">
          <div className="OnlineBookingList-guest-section">
            {/* <ReceptionistDashboard /> */}
            <ReceptionistDashboard receptionProfileShow={true} />
          </div>

          <div className="OnlineBookingList-content-section">
            <div className="OnlineBookingList-mobile-header">
              <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
              />
              <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
            </div>
            <div className="OnlineBookingList-content-head">
              <Link to="/StaffDashboard">Dashboard</Link>
            </div>
            <div className="OnlineBookingList-content-body">
              <div className="OnlineBookingList-bar-holder">
                <p>Bookings</p>
                <input type="text" placeholder="Search Guests" />
              </div>
              <div className="OnlineBookingList-content-holder">
                <div className="OnlineBookingList-table-section">
                  {/* <div className="OnlineBookingList-web-table-holder"> */}
                  <div className="OnlineBookingList-web-table-holder">
                    <table className="OnlineBookingList-web-table">
                      <div className="OnlineBookingList-thead">
                        <p>Name</p>
                        <p>Address</p>
                        <p> Contact</p>
                      </div>
                      
                      
                      
                      <div className="OnlineBookingList-display-plate">
                        {isLoading ? (
                          <CgSpinner
                            className="animate-spin"
                            style={{ margin: "auto" }}
                            size={20}
                          />
                        ) : error ? (
                          <p style={{ color: "red", textAlign: "center" }}>{error}</p> // Error message
                        ) : (
                          bookings.map((details, index) => (
                            <OnlineBookingListItem
                              names={details.fullName}
                              email={details.email}
                              phone={details.phoneNumber}
                              index={index}
                              key={index}
                            />
                        ))
                        )}
                      </div>

                      
                      
                      
                      
                    </table>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineBookingList;
export const guestRoomCount = GuestRoomDetails.length;
