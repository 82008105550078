import React, { useContext, useEffect, useState } from "react";
import "./ReceptionAccount.css"; 
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReceptionAccountTableRow from "../ReceptionAccountTableRow/ReceptionAccountTableRow";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import { Store } from "../../../../Store";

import { formatDistanceToNow } from "date-fns";

import { CgSpinner } from "react-icons/cg";

import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

const ReceptionAccount = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

 
  const [error, setError] = useState(null);
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { dispatch } = useContext(Store);
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const collectedBy = retrievedInfo?.fullName;
  
  const [isLoading, setIsLoading] = useState(false);
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  
  
    
    
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/ReceptionAccount", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/ReceptionAccount");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  useEffect(() => {

    
    const fetchPayments = async () => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/all-payments`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setPayments(data.data);

        // Show success toast
        // toast.success("Payments fetched successfully!");
      } catch (error) {
        setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
   

  fetchPayments();
    
}, [apiUrl]);




  
  
  
  

  return (
    <div className="ReceptionAccount">
      {/* websection starts here */}
      <div className="ReceptionAccount-web">
      
     
        <div className="ReceptionAccount-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>


        <div className="ReceptionAccount-content-section">
          <div className="ReceptionAccount-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>
          
          
          
          <div className="ReceptionAccount-content-head">
            <Link to="/">Back to Homepage</Link>
          </div>
          <div className="ReceptionAccount-content-body">
            <div className="ReceptionAccount-bar-holder">
              <div>
                <p>Account</p>
              </div>
              
              
              <div>
                <select name="Date" id="Date">
                  <option value="Date">Date</option>
                  <option value="Last Week">Month</option>
                  <option value="Year">Year</option>
                </select>
                <select name="Categoryate" id="Category">
                  <option value="Category">Category</option>
                  <option value="Room">Room</option>
                  <option value="Laundry">Laundry</option>
                  <option value="Bar">Bar</option>
                  <option value="Pool">Pool</option>
                  <option value="Restaurant">Restaurant</option>
                </select>
              </div>
              
              
              
            </div>

            <div className="ReceptionAccount-content-table-section">
              {/* table */}
              
              
              {isLoading && (
                <div className="spinner">
                  <CgSpinner className="spinner-icon" size={40} />
                  <p>Loading...</p>
                </div>
              )}

              {/* Display error if there's an error */}
              {error && (
                <div className="error">
                  <p>{error}</p>
                </div>
              )}
              
              
              
              
            {!isLoading && !error && (
            
            
              <div className="ReceptionAccount-web-table-holder">
                <table className="ReceptionAccount-web-table">
                  <thead>
                    <div className="header_div">
                      <span>Date</span>
                      <span>Category</span>
                      <span>Bill</span>
                      <span>Paid</span>
                      <span>Balance</span>
                    </div>
                  </thead>
                  <section>
                  
                  
                   {payments
                      ?.filter((content) => content.collectedBy === collectedBy)
                      .map((content) => {
                         const balance = content.bill - content.paid;
                         
                         const assignedDate = content.assignedDate 
                           ? new Date(content.assignedDate) 
                           : new Date(); // Fallback to current date
                           
                         const formattedDate = formatDistanceToNow(assignedDate, {
                           addSuffix: true,
                    });


                      return (
                        <ReceptionAccountTableRow
                          date={formattedDate}
                          category={content.category}
                          bill={content.bill}
                          paid={content.paid}
                          balance={`${balance}.00`}
                        />
                      );
                    })}
                  </section>
                </table>
              </div>
              
            )}  
              
            </div>
          </div>
        </div>
      </div>
      {/* websection ends here */}
    </div>
  );
};

export default ReceptionAccount;
