import React, { useContext, useEffect, useState } from "react";
import "./CashierIncoming.css"; 


import ReceptionistDashboard from "../CashierProfile/ReceptionistDashboard";

import CashierIncomingTableRow from "../CashierIncomingTableRow/CashierIncomingTableRow";

import ReceptionHeader from "../CashierHeader/ReceptionHeader";

import { Store } from "../../../../Store";

import { formatDistanceToNow } from "date-fns";

import { CgSpinner } from "react-icons/cg";

import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";



const CashierIncoming = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState(null); // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  
 
  const [error, setError] = useState(null);
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { dispatch } = useContext(Store);
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const receivedby = retrievedInfo.fullName;
  
  const [isLoading, setIsLoading] = useState(false);
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  let balances = {};
  
    
    
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/CashierIncoming", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/CashierIncoming");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  useEffect(() => {

    
    const fetchPayments = async () => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/all-payments`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setPayments(data.data);

        // Show success toast
        // toast.success("Payments fetched successfully!");
      } catch (error) {
        setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
   

  fetchPayments();
    
}, [apiUrl]);




  
  
  
  const handleInputChange = (id, value) => {
    if (!id) {
      toast.error("No identifier provided for the payment item.");
      return;
    }
    setPayments((prevPayments) =>
      prevPayments.map((payment) =>
        payment._id === id ? { ...payment, received: value } : payment
      )
    );
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const generateRandomPaymentId = () => {
    return Math.random().toString(36).substr(2, 9); 
  };



  const handleSavePayment = async (paymentId, updatedValue, bill, balanc, colors, receivedby) => {
    
    // Only generate a new color if the current color is null
    const color = colors || generateRandomColor();
    
    
    const paymentID = generateRandomPaymentId();
    try {
      const currentDate = new Date().toISOString();
      

      const bal = balanc == null || balanc === undefined 
      ? parseFloat(bill) - parseFloat(updatedValue) 
      : parseFloat(balanc) - parseFloat(updatedValue);

      
    
      const response = await fetch(`${apiUrl}/api/payment/update-payment/${paymentId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          bill: bill,
          balance: bal,
          received: updatedValue,
          receivedDate: currentDate, 
          receivedBy: receivedby,
          color: color, 
          paymentID: paymentID, 
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update payment");
      }

      toast.success("Payment updated successfully!");
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === paymentId
            ? { ...payment, received: updatedValue, color: color, paymentID: paymentID }
            : payment
        )
      );
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };
  
  
  
  

  return (
    <div className="ReceptionAccount">
      {/* websection starts here */}
      <div className="ReceptionAccount-web">
      
     
        <div className="ReceptionAccount-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>


        <div className="ReceptionAccount-content-section">
          <div className="ReceptionAccount-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>
          
          
          
          <div className="ReceptionAccount-content-head">
            <Link to="/">Back to Homepage</Link>
          </div>
          <div className="ReceptionAccount-content-body">
            <div className="ReceptionAccount-bar-holder">
              <div>
                <p>Account</p>
              </div>
              
              
              <div>
                <select name="Date" id="Date">
                  <option value="Date">Date</option>
                  <option value="Last Week">Month</option>
                  <option value="Year">Year</option>
                </select>
                <select name="Categoryate" id="Category">
                  <option value="Category">Category</option>
                  <option value="Room">Room</option>
                  <option value="Laundry">Laundry</option>
                  <option value="Bar">Bar</option>
                  <option value="Pool">Pool</option>
                  <option value="Restaurant">Restaurant</option>
                </select>
              </div>
              
              
              
            </div>

            <div className="ReceptionAccount-content-table-section">
              {/* table */}
              
              
              {isLoading && (
                <div className="spinner">
                  <CgSpinner className="spinner-icon" size={40} />
                  <p>Loading...</p>
                </div>
              )}

              {/* Display error if there's an error */}
              {error && (
                <div className="error">
                  <p>{error}</p>
                </div>
              )}
              
              
              
              
            {!isLoading && !error && (
            
            
              <div className="ReceptionAccount-web-table-holder">
               
               <div className="scrollable-table">
   
                <table className="ReceptionAccount-web-table">
                  <thead>
                    <div className="header_div">
                      <span>Date</span>
                      <span>Category</span>
                      <span>Collected By</span>
                      <span>Bill</span>
                      <span>Recieved</span>
                      <span>Recieved Date</span>
                      <span>Balance</span>
                      <span>Last Recieved By</span>
                    </div>
                  </thead>
                  <section>
                      {payments.map((content) => {
                        const balance = content.bill - content.balance;
                        
                        
                        // Store balance in the global `balances` object using the content's ID
                        balances[content._id] = balance;
    
                        
                        
                        const assignedDate = content.assignedDate
                          ? new Date(content.assignedDate)
                          : new Date();
                        const formattedDate = formatDistanceToNow(assignedDate, {
                          addSuffix: true,
                        });
                        
                        

                      return (

                           
                          <CashierIncomingTableRow
                            key={content._id}
                            color={content.color || "black"}
                            date={formattedDate}
                            category={content.category || "N/A"}
                            collectedBy={(content.collectedBy || "N/A").split(" ")[0]}
                            bill={content.bill || "N/A"}
                            received={
                              (content.received == null || content.received === "") ? ( 
                              <input
                                type="number"
                                style={{ width: "70px", color: "black" }}
                                value={content.received || ""}
                                onClick={() => {
                                 setSelectedPayment(content);
                                 setIsModalOpen(true);
                                }}
                                onChange={(e) => {
                                 const newValue = parseFloat(e.target.value) || 0;
                                 if (newValue >= 0 && newValue <= (content.balance == null || content.balance === undefined ? content.bill : content.balance)
)                               {
                                   handleInputChange(content._id, newValue);
                                 } else {
                                   toast.error(`Received Money must be more than 0 and less than ${content.bill} amount`);
                                   return;
                                 }
                                }}
                              />
                               ) : (
                                 content.received
                               )
                            }
                            receivedDate={
                              content.receivedDate
                              ? new Date(content.receivedDate).toLocaleDateString("en-US")
                              : "N/A"
                            }

                            balance={`${content?.balance || "0.0"}.00`}
                            receivedBy={content.receivedBy || "N/A"}
                          />
                          
                       
                        
                        );
                      })}
                    </section>
                    
                    
                </table>
                
              </div>
              
              
              </div>
              
            )}  
              
            </div>
          </div>
        </div>
      </div>
      {/* websection ends here */}
      
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Update Payment</h2>
            <input
              type="number"
              style={{ color: selectedPayment?.color || "black" }}
              value={selectedPayment?.received || ""}
              onChange={(e) => {
               const newValue = parseFloat(e.target.value) || 0;
               if (newValue >= 0 && newValue <= (selectedPayment.balance == null || selectedPayment.balance === undefined ? selectedPayment.bill : selectedPayment.balance)) {
                 setSelectedPayment({
                  ...selectedPayment,
                  received: e.target.value,
                })
               } else {
                 toast.error(`Received Money must be more than 0 and less than ${selectedPayment.bill} amount`);
                 return;
                }
               } 
              }
            />
            
          <div className="modal-buttons">
          
            <button
              onClick={() =>
               handleSavePayment(selectedPayment._id, selectedPayment.received, selectedPayment.bill, selectedPayment.balance, selectedPayment.color, receivedby)
              }
            >
              Update
              
            </button>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          
          </div>
          
          
          </div>
        </div>
      )}
      
    </div>
  );
};

export default CashierIncoming;





