/** @format */

import React, { useContext, useEffect, useState } from "react";
import play_btn from "../../../../assets/play-icon.svg";


import { CgSpinner } from "react-icons/cg";
import { Store } from "../../../../Store";



import ReceptionHeader from "../BartenderHeader/ReceptionHeader";
import ReceptionistDashboard from "../BartenderProfile/ReceptionistDashboard";
import "./GuestDashBoardRoom.css";
import { DateFormatter } from "../../../../utils/DateFOrmatter";




import { useParams, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";





const  BarGuestDashBoardRoom = () => {
 
  const { uniqueNo } = useParams();
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);

  const [loadingStates, setLoadingStates] = React.useState({});
  const [identity, setIdentity] = useState([]);
  const [payments, setPayments] = useState([]);
  const [room, setRoom] = useState(null);
  const [name, setName] = useState(null);
  const [indexx, setIndexx] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [error, setError] = useState(null);
  const { state: { staffInfo }, dispatch } = useContext(Store);
  const [guest, setGuest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  
 
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  

  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/BarGuestDashboardRoom/:uniqueNo", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/BarGuestDashboardRoom/:uniqueNo");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  useEffect(() => {
  
    const fetchGuest = async (identifier) => {
      // if (!uniqueNo) return;
      
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/single-guest/${identifier}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setGuest(data.data);
        // toast.success("Guest data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    
    

    const fetchRoomDetails = async (identifier) => {
      // if (!identifier) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/rooms/view-room/${identifier}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch guest details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setRoom(data.data);

        // Show success toast
        // toast.success("Room details fetched successfully!");
      } catch (error) {
        setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
  
  
    const fetchPayments = async (index) => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/user-payments/${index}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setPayments(data.data);

        // Show success toast
        // toast.success("Payments fetched successfully!");
      } catch (error) {
        setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
    const handleFetch = async () => {
    if (uniqueNo) {
      const savedProps = JSON.parse(localStorage.getItem("guestDashboardProps")) || {};
      if (savedProps.id && savedProps.uniqueNo) {
        // Use savedProps directly for immediate availability
        const identifier = savedProps.uniqueNo || "N/A";
        const index = savedProps.id || "N/A";
        const name = savedProps.names || "N/A";

        // Update state after ensuring the values are ready
        setIdentity(identifier);
        setIndexx(index);
        setName(name);

        // Pass the values directly to fetchGuest if needed
        await fetchGuest(identifier);
        await fetchRoomDetails(identifier);
        await fetchPayments(index)
      } else {
        toast.error("Invalid guest dashboard data!");
        navigate("/BartenderManageGuest");
      }
    } else {
      navigate("/BartenderManageGuest");
      toast.error("Please select a customer!");
    }
  };

  handleFetch();
    
}, [apiUrl, uniqueNo, navigate]);
  
  
  
  
 
  
  
  
  
  const updateRoom = async (item_id) => {
  try {
    // Prepare the payload
    const roomId = item_id;
    const payload = {
      payload: { isAvailable: true, type: "" },
    };

    // Make the API call
    const response = await fetch(`${apiUrl}/api/rooms/checkout-room/${roomId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Failed to update room with ID ${item_id}`);
    }

    // Parse and return the result
    const result = await response.json();

    // Handle success
    toast.success("Room updated successfully!");
    return result;
  } catch (error) {
    // Handle errors
    console.error("Error updating room:", error);
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};




/*
 const closeTransaction = async () => {
  try {
    
    const guestId = identity;
    // Filter payments that have not been checked out
    const outstandingPayments = payments.filter((pay) => (pay.bill - pay.paid) > 0);

    if (outstandingPayments.length > 0) {
      toast.error("Customer still have outstanding payments!");
      return;
    }

    // Call the delete API if no outstanding payments
    const response = await fetch(`${apiUrl}/api/manage-guest/delete/${guestId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (!response.ok) {
      toast.error(result.message || "Failed to delete Customer.");
      throw new Error(result.message || "Failed to delete Customer.");
    }
    
    if (room && room.length > 0) {
      const unavailableRooms = room.filter((rooms) => !rooms.isAvailable);

      await Promise.all(
        unavailableRooms.map(async (rooms) => {
          try {
            await updateRoom(rooms._id);
          } catch (error) {
            toast.error(`Failed to update room with ID ${rooms._id}`, error)
            console.error(`Failed to update room with ID ${rooms._id}`, error);
          }
        })
       );
    }
    
    
    navigate("/BartenderManageGuest");
    
    // Notify the user of successful deletion
    toast.success(result.message || "All Transactions with this Customer is ended successfully!");
    
  } catch (error) {
    // Handle errors and show detailed messages
    console.error("Error processing payments:", error);
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};

*/







 


  const handlePlayButtonClick = () => {
    setIsVisible(!isVisible);
  };




  return (
    <>
      <div className="GuestDashBoardRoom">
        <div className="GuestDashBoardRoom-web">
        
        
        
        
        
          <div className="GuestDashBoardRoom-profile-section">
            <ReceptionistDashboard receptionProfileShow={true} />
          </div>
          
          

          <div className="GuestDashBoardRoom-content-section">
            <div className="GuestDashBoardRoom-mobile-header">
              <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
              />
              <ReceptionHeader
                setReceptionProfileShow={setReceptionProfileShow}
              />
            </div>
            
            
            
            <div className="GuestDashBoardRoom-content-head">
              <Link to="/">Back to Homepage</Link>
            </div>
            
            
            <div className="GuestDashBoardRoom-content-body">
              <div className="GuestDashBoardRoom-option-details">
                <div className="GuestDashBoardRoom-user-detail">
                  {/* User details can be added here if needed */}
                  <p>{name || guest.fullName || ""}</p>
                  <p>{guest.userUniqueId || ""}</p>
                </div>
                
                
                
                <div className="GuestDashBoardRoom-recep-detail">
                  <Link
                    to={`/BarUpdateGuestPayment/${identity}`}
                    onClick={() =>
                      localStorage.setItem(
                      "guestDashboardProps",
                      JSON.stringify({ identity, indexx, name})
                    )
                    }
                  >
                    <button>Payments</button>
                  </Link>
                  
                  <Link
                    to={`/Receipt/${identity}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      localStorage.setItem(
                      "guestDashboardProps",
                      JSON.stringify({ identity, indexx, name })
                    )
                    }
                  >
                   <button>Receipt</button>
                  </Link>

                  
                  
                  {/* <form onSubmit={closeTransaction}>
                    <button type="submit">Delete</button>
                  </form>
                  */}

                </div>
                
                
                
              </div>
              <div className="GuestDashBoardRoom-account-title">
                <h2>Accounts</h2>
              </div>

              <div className="GuestDashBoardRoom-center-holder">
                <div className="GuestDashBoardRoom-content-table-section">
                
                
                  <main className="GT-container">
                  
                  
                  
                    {staffInfo &&
staffInfo.position &&
(staffInfo.position === "Receptionist" ||
  staffInfo.position === "Admin") ? (
  <div className="GT-table-option">
    <div className="GT-option-Button">
      <div>
        <img
          src={play_btn}
          alt="Play Button"
          className={`${
            isVisible ? "clicked-class" : "clicked-back"
          }`}
          onClick={handlePlayButtonClick}
        />
        <p>Room:</p>
      </div>
    </div>
    {isVisible && (
      <div className="GT-option-Body">
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Room</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
            <p>Status</p>  {/*Added status column */}
          </div>
          {room && room.length > 0 ? (
            room
              .filter((room) => room.type === "Checkin")
              .map((item, index) => {
                const matchingPayment = payments?.find(
                  (pay) =>
                    pay.category === "Room" &&
                    pay.comment.includes(`id:${item._id}`)
                );

                const balance = matchingPayment
                  ? matchingPayment.bill - matchingPayment.paid
                  : 0;

                const isAvailable = item.isAvailable;
                
                
                

                const handleCheckOut = async (item_id, matchingPayment) => {
                  
                  setLoadingStates((prev) => ({ ...prev, [item._id]: true }));

                  try {
                     if (
                     matchingPayment &&
                     matchingPayment.paid >= 0.1 * matchingPayment.bill
                    ) {
                     await updateRoom(item_id);
                     toast.success("Room checked out successfully!");
                    } else {
                      toast.error(
                      "Insufficient payment! At least 10% is required to check out."
                      );
                    }
                  } catch (error) {
                    toast.error("An error occurred while checking out.");
                  } finally {
                    setLoadingStates((prev) => ({ ...prev, [item._id]: false }));
                    }
                  };



                return (
                  <div key={index} className="GT-Table-row">
                    <p className="first-item">{item?.name || "N/A"}</p>
                    <p>
                      <DateFormatter
                        isoDate={item?.assignedDate || ""}
                      />
                    </p>
                    <p>{item?.price ? `#${Number(item.price).toLocaleString()}` : "#0.0"}</p>

                    <p>{matchingPayment?.paid || "0.0"}</p>
                    <p>{balance || "0.0"}</p>
                    <p>
                      <button
                        className={`status-button ${
                          !isAvailable ? "available" : "unavailable"
                        }`}
                        onClick={() => handleCheckOut(item._id, matchingPayment)}
                        disabled={isAvailable || loadingStates[item._id]}
                      >
                        {loadingStates[item._id] ? (
                          <CgSpinner className="spinner" />
                            ) : !isAvailable ? (
                               "Checked In"
                            ) : (
                               "Checked Out"
                            )}
                      </button>
                    </p>
                  </div>
                );
              })
          ) : (
            <div style={{ color: "red", textAlign: "center" }}></div>
          )}
        </section>
      </div>
    )}
  </div>
) : (
  ""
)}

                    
                    
                    
                    
                    
                    
                    
                    
<div className="GT-table-option">
  <div className="GT-option-Button">
    <div>
      <img
        src={play_btn}
        alt="Play Button"
        className={`${
          isVisible1 ? "clicked-class" : "clicked-back"
        }`}
        onClick={() => setIsVisible1(!isVisible1)}
      />
      <p>Laundry:</p>
    </div>
  </div>
  {isVisible1 && (
    <div className="GT-option-Body">
      {isLoading && ( // Show loading message
        <div className="loading-message">
          <p>Please wait for data</p>
        </div>
      )}
      {error && ( // Show error message if present
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {!isLoading && !error && ( // Only render the table when not loading and no error
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Items</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
          </div>
          {payments &&
            payments
              .filter((items) => items.checkedout === false)
              .map((items) =>
                items.category === "Laundry" ? (
                  <div
                    key={items._id}
                    className="GT-Table-row"
                  >
                    <p className="first-item">
                      {items.comment}
                    </p>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Date:</p>
                      <p>
                        <DateFormatter
                          isoDate={items?.updatedAt || ""}
                        />
                      </p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bill:</p>
                      <p>{items.bill}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Paid:</p>
                      <p>{items.paid}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bal:</p>
                      <p>{items.bill - items.paid}</p>{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
        </section>
      )}
    </div>
  )}
</div>

                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    <div className="GT-table-option">
  <div className="GT-option-Button">
    <div>
      <img
        src={play_btn}
        alt="Play Button"
        className={`${
          isVisible2 ? "clicked-class" : "clicked-back"
        }`}
        onClick={() => setIsVisible2(!isVisible2)}
      />
      <p>Restaurant:</p>
    </div>
  </div>
  {isVisible2 && (
    <div className="GT-option-Body">
      {isLoading && ( // Show loading message
        <div className="loading-message">
          <p>Please wait for data</p>
        </div>
      )}
      {error && ( // Show error message if present
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {!isLoading && !error && ( // Only render the table when not loading and no error
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Items</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
          </div>
          {payments &&
            payments
              .filter((items) => items.checkedout === false)
              .map((items) =>
                items.category === "Restaurant" ? (
                  <div
                    key={items._id}
                    className="GT-Table-row"
                  >
                    <p className="first-item">
                      {items.comment}
                    </p>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Date:</p>
                      <p>
                        <DateFormatter
                          isoDate={items?.updatedAt || ""}
                        />
                      </p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bill:</p>
                      <p>{items.bill}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Paid:</p>
                      <p>{items.paid}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bal:</p>
                      <p>{items.bill - items.paid}</p>{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
        </section>
      )}
    </div>
  )}
</div>

                    
                    
                    
                    
                    <div className="GT-table-option">
  <div className="GT-option-Button">
    <div>
      <img
        src={play_btn}
        alt="Play Button"
        className={`${
          isVisible3 ? "clicked-class" : "clicked-back"
        }`}
        onClick={() => setIsVisible3(!isVisible3)}
      />
      <p>Bar:</p>
    </div>
  </div>
  {isVisible3 && (
    <div className="GT-option-Body">
      {isLoading && ( // Show loading message
        <div className="loading-message">
          <p>Please wait for data</p>
        </div>
      )}
      {error && ( // Show error message if present
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {!isLoading && !error && ( // Only render the table when not loading and no error
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Items</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
          </div>
          {payments &&
            payments
              .filter((items) => items.checkedout === false)
              .map((items) =>
                items.category === "Bar" ? (
                  <div
                    key={items._id}
                    className="GT-Table-row"
                  >
                    <p className="first-item">
                      {items.comment}
                    </p>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Date:</p>
                      <p>
                        <DateFormatter
                          isoDate={items?.updatedAt || ""}
                        />
                      </p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bill:</p>
                      <p>{items.bill}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Paid:</p>
                      <p>{items.paid}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bal:</p>
                      <p>{items.bill - items.paid}</p>{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
        </section>
      )}
    </div>
  )}
</div>

                    
                    
                    
                    
                    

                    {/* poll */}
                    <div className="GT-table-option">
  <div className="GT-option-Button">
    <div>
      <img
        src={play_btn}
        alt="Play Button"
        className={`${
          isVisible5 ? "clicked-class" : "clicked-back"
        }`}
        onClick={() => setIsVisible5(!isVisible5)}
      />
      <p>Pool:</p>
    </div>
  </div>
  {isVisible5 && (
    <div className="GT-option-Body">
      {isLoading && ( // Show loading message
        <div className="loading-message">
          <p>Please wait for data</p>
        </div>
      )}
      {error && ( // Show error message if present
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {!isLoading && !error && ( // Only render the table when not loading and no error
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Description</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
          </div>
          {payments &&
            payments
              .filter((items) => items.checkedout === false)
              .map((items) =>
                items.category === "Pool" ? (
                  <div
                    key={items._id}
                    className="GT-Table-row"
                  >
                    <p className="first-item">
                      {items.comment}
                    </p>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Date:</p>
                      <p>
                        <DateFormatter
                          isoDate={items?.updatedAt || ""}
                        />
                      </p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bill:</p>
                      <p>{items.bill}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Paid:</p>
                      <p>{items.paid}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bal:</p>
                      <p>{items.bill - items.paid}</p>{" "}
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
        </section>
      )}
    </div>
  )}
</div>









                    {/* poll  */}

                    <div className="GT-table-option">
  <div className="GT-option-Button">
    <div>
      <img
        src={play_btn}
        alt="Play Button"
        className={`${
          isVisible4 ? "clicked-class" : "clicked-back"
        }`}
        onClick={() => setIsVisible4(!isVisible4)}
      />
      <p>Others:</p>
    </div>
  </div>
  {isVisible4 && (
    <div className="GT-option-Body">
      {isLoading && ( // Display loading message
        <div className="loading-message">
          <p>Please wait for data</p>
        </div>
      )}
      {error && ( // Display error message
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      {!isLoading && !error && ( // Render the table only when no loading or error
        <section className="GT-table">
          <div className="GT-Table-row table-row-header">
            <p>Items</p>
            <p>Date</p>
            <p>Bill</p>
            <p>Paid</p>
            <p>Bal</p>
          </div>
          {payments &&
            payments
              .filter((items) => items.checkedout === false)
              .map((items) =>
                items.category === "Others" ? (
                  <div
                    key={items._id}
                    className="GT-Table-row"
                  >
                    <p className="first-item">
                      {items.comment}
                    </p>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Date:</p>
                      <p>
                        <DateFormatter
                          isoDate={items?.updatedAt || ""}
                        />
                      </p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bill:</p>
                      <p>{items.bill}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Paid:</p>
                      <p>{items.paid}</p>
                    </div>
                    <div className="GuestDashBoardRoom-mob-row">
                      <p>Bal:</p>
                      <p>{items.bill - items.paid}</p>
                      {/* Display balance */}
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
        </section>
      )}
    </div>
  )}
</div>

                    
                    
                    
                    
                  </main>
                  
                  
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarGuestDashBoardRoom;
